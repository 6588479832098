// extracted by mini-css-extract-plugin
export var active = "page-module--active--bd1e6";
export var activeTab = "page-module--activeTab--be18a";
export var caseContainer = "page-module--caseContainer--0d559";
export var caseContent = "page-module--caseContent--73c4b";
export var caseName = "page-module--caseName--d2a03";
export var caseStory = "page-module--caseStory--d173a";
export var caseTab = "page-module--caseTab--dbb57";
export var caseTabs = "page-module--caseTabs--25833";
export var caseTitle = "page-module--caseTitle--a4952";
export var ctaButton = "page-module--ctaButton--6934c";
export var entrancePage = "page-module--entrancePage--391cf";
export var faqAnswer = "page-module--faqAnswer--c8b55";
export var faqContainer = "page-module--faqContainer--52f0a";
export var faqIcon = "page-module--faqIcon--ff788";
export var faqItem = "page-module--faqItem--91442";
export var faqQuestion = "page-module--faqQuestion--7ee73";
export var faqSection = "page-module--faqSection--9e406";
export var introPoint = "page-module--introPoint--46265";
export var introPointIcon = "page-module--introPointIcon--0331d";
export var introPointsContainer = "page-module--introPointsContainer--d2cbe";
export var introSectionText = "page-module--introSectionText--f8436";
export var introSectionTitle = "page-module--introSectionTitle--aa389";
export var mainContent = "page-module--mainContent--3d5a7";
export var mainImage = "page-module--mainImage--386e6";
export var mainSection = "page-module--mainSection--c734a";
export var mainTitle = "page-module--mainTitle--987c1";
export var profilePicture = "page-module--profilePicture--246b7";
export var sectionDescription = "page-module--sectionDescription--44017";
export var sectionTitle = "page-module--sectionTitle--285b8";
export var slideContent = "page-module--slideContent--db9a1";
export var slideDetails = "page-module--slideDetails--7c015";
export var slideSection = "page-module--slideSection--6f08f";
export var tab = "page-module--tab--69fb7";
export var tabContainer = "page-module--tabContainer--90a7c";
export var userCaseSection = "page-module--userCaseSection--d1214";
export var userIcon = "page-module--userIcon--45040";