import React, { useState } from "react";
import * as styles from '../styles/page.module.css';
import { FaRobot, FaBullseye, FaCalendarAlt, FaClock, FaChartLine, FaCoffee, FaUserGraduate } from 'react-icons/fa';

import SEO from "../components/seo.jsx"
import { StaticImage } from "gatsby-plugin-image"
import {Link} from 'gatsby'

import { useTheme } from '../context/ThemeContext';
import Layout from "../components/layout.js";

// Add this constant for FAQ data
const faqData = [
  {
    question: "What is STAIRAZ and how can it help college students?",
    answer: "STAIRAZ is an AI-powered personal college advisor and planner designed to help students get a clear view of their goals and create doable step-by-step plans. We use the latest AI technology to provide personalized guidance, optimize time management, and help students achieve their academic and career objectives efficiently."
  },
  {
    question: "Who can benefit from using STAIRAZ?",
    answer: "STAIRAZ is beneficial for a wide range of users, from pre-college students to recent graduates. Whether you're planning your college journey, looking to improve your academic performance, or preparing for your next career move, STAIRAZ can help you clarify your goals and create an effective plan to achieve them."
  },
  {
    question: "How does STAIRAZ create personalized plans for users?",
    answer: "STAIRAZ uses advanced AI to analyze your goals, current academic status, and interests. It then creates a customized plan that breaks down your objectives into actionable steps. The AI continuously updates the plan based on the latest information and trends in your field of study or chosen career path."
  },
  {
    question: "Can STAIRAZ help with daily task management?",
    answer: "Yes, STAIRAZ can create a daily to-do list optimized by AI. It takes into account your goals, deadlines, and personal schedule to help you effectively manage your time and track your daily tasks. This feature ensures you're always making progress towards your long-term objectives."
  },
  {
    question: "How does STAIRAZ adapt to changes in a student's goals or circumstances?",
    answer: "STAIRAZ is designed to be flexible and responsive. If your goals change or new opportunities arise, the AI can quickly adjust your plan. It also learns from your progress and feedback, continuously refining its recommendations to better suit your needs and circumstances."
  },
  {
    question: "Is STAIRAZ only for academic planning, or can it help with career preparation too?",
    answer: "STAIRAZ is designed to assist with both academic and career planning. It can help you choose the right courses for your career path, identify relevant internship opportunities, prepare for job interviews, and even guide you in developing important skills for your chosen field."
  },
  {
    question: "How does STAIRAZ compare to traditional college advising services?",
    answer: "While traditional advising is valuable, STAIRAZ offers 24/7 availability, personalized planning based on vast amounts of data, and the ability to quickly adapt plans as circumstances change. It complements traditional services by providing ongoing support and detailed, actionable plans tailored to each student's unique situation."
  }
];



export const Head = () => (
  <SEO 
      title="STAIRAZ: Your Personal College Advisor & Planner"
      description="     
      STAIRAZ AI will help you clarify your career goals, make your personal plan for your academic and career success, and track your progress.
      "
      questions ={faqData}
      pathname="/"

      >
        <html lang="en" />
 <meta name="keywords" content="AI-Powered Service, Job Application, Cover Letter Generators, Free Tools, STAIRAZ"/>
  </SEO>
);



const EntrancePage = () => {
  const { isDarkMode } = useTheme();
  const [activeCase, setActiveCase] = useState(0);
  const [expandedFaq, setExpandedFaq] = useState(null);

  const slides = [
    {
      title: "AI Resume Analysis",
      content: "Get instant feedback on your resume with our AI-powered analysis tool. Improve your chances of landing interviews with tailored suggestions.",
    },
    {
      title: "Personal Statement Assistant",
      content: "Craft compelling personal statements for college applications or job opportunities with our AI writing assistant.",
    },
    {
      title: "Interview Preparation",
      content: "Practice for interviews with our AI-driven mock interview simulator. Receive feedback on your responses and body language.",
    },
  ];

  const userCases = [
    {
      name: "Alex",
      title: "Freshman Math Major",
      story: "Alex was a freshman who had always done well in math, so picking it as his college major seemed like the right choice. But after a few weeks, he started feeling lost. The classes weren't what he expected, and he wasn't sure what he could do with a math degree. That's when he tried STAIRAZ consulting. After talking to the AI advisor, things became clearer. The AI showed him different career options, and Alex decided he wanted to become a data scientist. With STAIRAZ's help, he made a 3-year plan, picking the right classes, getting hands-on experience, and applying for internships. STAIRAZ even turned it into a daily calendar to keep him on track. Three years later, Alex completed an internship at Google and got a full-time offer",
    },
    {
      name: "Elsa",
      title: "Junior CS Student",
      story: "Elsa, a third-year computer science student, knew she wanted to become a software engineer. But she realized there was a gap in her skills—she didn't know much about building websites, which is important for her career. To fix this, she turned to STAIRAZ for help. The AI planner made her a 3-month plan that was easy to follow. It included short courses to help her learn the basics of building websites and even suggested a small project she could complete and add to her resume. With this plan, Elsa felt confident she could gain the skills she needed for her future job.",
    },
    {
      name: "Michael",
      title: "Sophomore Student",
      story: "Michael, a second-year student, started a new semester feeling overwhelmed. He had signed up for seven courses, each with different lecture times, deadlines, and tasks. In the past, he had trouble keeping up with everything and was worried he might miss assignments again. Wanting to stay on top of things this time, he turned to STAIRAZ. By simply uploading his course syllabi, STAIRAZ created a clear daily calendar for him, listing all his tasks and deadlines. It didn't stop there—it also helped him manage his time better so he could complete everything on time and still have time to relax. Michael finally felt organized and confident about the semester ahead.",
    }
  ];

  // Add this function to toggle FAQ answers
  const toggleFaq = (index) => {
    if (expandedFaq === index) {
      setExpandedFaq(null);
    } else {
      setExpandedFaq(index);
    }
  };

  return (
    <Layout>
      <div className={styles.entrancePage}>
        <section className={styles.mainSection}>
          <div className={styles.mainContent}>
            <h1 className={styles.mainTitle}>Stairaz: Your Personal College Advisor & Planner</h1>
            <p>Create your personal plan from long-term goals to short-term targets, and build a roadmap to your college success.</p>
            <button onClick={() => window.location.href='/get-started'} className={styles.ctaButton}>Start your journey</button>
          </div>
          <div className={styles.mainImage}>
            <StaticImage src="../images/entrancePage/productImage1.png" alt="STAIRAZ" />
          </div>
        </section>

        <section className={styles.introSectionText}>
        <h2 className={styles.sectionTitle}>Feature Spotlight</h2>
        <p>Discover the main feature of STAIRAZ services.</p>
          <div className={styles.introPointsContainer}>
            <div className={styles.introPoint}>
              <h3><FaRobot className={styles.introPointIcon} /> Chat-Based Personal Advisor</h3>
              <p>Get personalized advice for your college dreams and plans through a seamless conversation—just like speaking with a real expert. Our chat-based AI advisor provides tailored guidance to help you achieve your academic goals.</p>
            </div>
            <div className={styles.introPoint}>
              <h3><FaBullseye className={styles.introPointIcon} /> Convert Goals Into Actionable Plans</h3>
              <p>Turn your ambitions into achievable steps. Whether you want to learn Python in three months or prepare for an internship, our AI planner breaks down your goal into a structured plan, offering course suggestions and hands-on opportunities like coding competitions.</p>
            </div>
            <div className={styles.introPoint}>
              <h3><FaCalendarAlt className={styles.introPointIcon} /> Create Your Personalized Calendar</h3>
              <p>Take your plan to the next level by generating a daily calendar that tracks every step. Input your course syllabus, deadlines, and events to easily manage your time and stay on top of your tasks with minimal effort.</p>
            </div>
            <div className={styles.introPoint}>
              <h3><FaClock className={styles.introPointIcon} /> Optimize Your Time Management</h3>
              <p>Need more study time this week? Or maybe you're planning to relax? Let our AI adjust your schedule to fit your life. Whether intense or easygoing, the AI creates a dynamic, flexible plan to match your pace.</p>
            </div>
            <div className={styles.introPoint}>
              <h3><FaChartLine className={styles.introPointIcon} /> Track Your Progress and Analyze Goals</h3>
              <p>Stay on track with comprehensive progress reports. Our service evaluates how you're doing, helping you know if you're ahead, on schedule, or falling behind. Adjustments are made based on real-time performance insights.</p>
            </div>
            <div className={styles.introPoint}>
              <h3><FaCoffee className={styles.introPointIcon} />The Cup of Coffee Policy</h3>
              <p>We believe quality guidance should be accessible. That's why we offer our premium service at a price less than a cup of coffee per month. With cutting-edge AI replacing unnecessary human costs, we ensure affordability without sacrificing value.</p>
            </div>
          </div>
        </section>

        {/* <section className={styles.slideSection}>
          <div className={styles.slideContent}>
            <h2 className={styles.sectionTitle}>User cases</h2>
            <p>Discover how STAIRAZ can revolutionize your career and academic journey.</p>
            
            <div className={styles.tabContainer}>
              {slides.map((slide, index) => (
                <button
                  key={index}
                  className={`${styles.tab} ${activeTab === index ? styles.activeTab : ''}`}
                  onClick={() => setActiveTab(index)}
                >
                  {slide.title}
                </button>
              ))}
            </div>
            
            <div className={styles.slideDetails}>
              <h3>{slides[activeTab].title}</h3>
              <p>{slides[activeTab].content}</p>
            </div>
          </div>
        </section> */}

        <section className={styles.userCaseSection}>
          <h2 className={styles.sectionTitle}>Success Stories</h2>
          <p className={styles.sectionDescription}>Discover how STAIRAZ is transforming students' academic journeys and future careers.</p>
          
          <div className={styles.caseContainer}>
            <div className={styles.caseTabs}>
              <div
                className={`${styles.caseTab} ${activeCase === 0 ? styles.activeTab : ''}`}
                onClick={() => setActiveCase(0)}
              >
                <StaticImage
                  src="../images/entrancePage/userCaseProfile1.png"
                  alt="Elsa's profile"
                  className={styles.profilePicture}
                  width={60}
                  height={60}
                />
              </div>
              <div
                className={`${styles.caseTab} ${activeCase === 1 ? styles.activeTab : ''}`}
                onClick={() => setActiveCase(1)}
              >
                <StaticImage
                  src="../images/entrancePage/userCaseProfile2.png"
                  alt="Alex's profile"
                  className={styles.profilePicture}
                  width={60}
                  height={60}
                />
              </div>
              <div
                className={`${styles.caseTab} ${activeCase === 2 ? styles.activeTab : ''}`}
                onClick={() => setActiveCase(2)}
              >
                <StaticImage
                  src="../images/entrancePage/userCaseProfile3.png"
                  alt="Michael's profile"
                  className={styles.profilePicture}
                  width={60}
                  height={60}
                />
              </div>
            </div>
            <div className={styles.caseContent}>
          
              <h3 className={styles.caseName}>{userCases[activeCase].name}</h3>
              <p className={styles.caseStory}>{userCases[activeCase].story}</p>
            </div>
          </div>
        </section>

        <section className={styles.faqSection}>
          <h2 className={styles.sectionTitle}>Frequently Asked Questions</h2>
          <div className={styles.faqContainer}>
            {faqData.map((faq, index) => (
              <div key={index} className={`${styles.faqItem} ${expandedFaq === index ? styles.active : ''}`}>
                <button
                  className={styles.faqQuestion}
                  onClick={() => toggleFaq(index)}
                >
                  {faq.question}
                  <span className={styles.faqIcon}>▼</span>
                </button>
                <div className={styles.faqAnswer}>{faq.answer}</div>
              </div>
            ))}
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default EntrancePage;
